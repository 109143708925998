@import '../../../../scss/_theme-bootstrap';

.country-chooser-banner {
  display: flex;
  line-height: 1;
  margin-top: 20px;
  margin-#{$rdirection}: 10px;
  opacity: 0.9;
  @include breakpoint($landscape-up) {
    margin-#{$rdirection}: 30px;
  }
  &__name {
    flex-direction: row;
    font-weight: bold;
    margin-#{$rdirection}: 5px;
    @include breakpoint($landscape-up) {
      letter-spacing: 1.5px;
    }
    a {
      color: $white;
      font-family: $font--pretendard-regular;
      font-size: 12px;
      text-decoration: none;
      @include breakpoint($landscape-up) {
        font-size: 16px;
      }
    }
  }
  &__language {
    flex-direction: row;
    border-#{$ldirection}: solid 1px $white;
    a {
      color: $white;
      font-family: $font--pretendard-regular;
      font-size: 12px;
      margin: 0 5px;
      text-decoration: none;
      @include breakpoint($landscape-up) {
        font-size: 16px;
      }
    }
  }
}
